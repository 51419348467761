import { FC, useEffect, useRef, useState } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';

import { getCSS, getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils';
import { fetchDataToGraph } from '../apis/requestsApi';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

interface ChartsIntegratorsProps {
  selectedCustomer: string;
  selectedRange: string;
  startDate: string;
  endDate: string;
  timezone: string;
}

const ChartsIntegrators: FC<ChartsIntegratorsProps> = (props) => {
  const { selectedCustomer, selectedRange, startDate, endDate, timezone } = props;
  const [isLoading, setIsLoading] = useState(true);
  const chartRef = useRef<HTMLDivElement | null>(null);
  const chartRefMounts = useRef<HTMLDivElement | null>(null);

  const [dataToGraph, setDataToGraph] = useState<any>(null);
  const [dataToGraphMounts, setDataToGraphMounts] = useState<any>(null);




  useEffect(() => {
    const fetchDataToGraphF = async () => {

      setIsLoading(true); // <- Agregar esto

      try {
        const reportParams = {
          customer: selectedCustomer,
          selectedRange: selectedRange,
          startDate: startDate,
          endDate: endDate,
          timezone: timezone,
          typeReport: 'Integrator',
          view: 'graph1',
        };

        const reportParamsMounts = {
          customer: selectedCustomer,
          selectedRange: selectedRange,
          startDate: startDate,
          endDate: endDate,
          timezone: timezone,
          typeReport: 'Integrator',
          view: 'graph2',
        };



        const data = await fetchDataToGraph(reportParams);

        console.log('DAT', data)
        const dataMounts = await fetchDataToGraph(reportParamsMounts);


        setDataToGraph(data);

        setDataToGraphMounts(dataMounts);




      } catch (error) {
        console.error('Error al obtener los datos:', error);
      } finally {
        setIsLoading(false)
      }
    };

    fetchDataToGraphF();
  }, [selectedCustomer, selectedRange, startDate, endDate, timezone]);

  useEffect(() => {
    const chart = refreshChart();
    const chartMount = refreshChartMount();


    return () => {
      if (chart) {
        chart.destroy();

      }

      if (chartMount) {

        chartMount.destroy();
      }

    };
  }, [chartRef, chartRefMounts, dataToGraph, dataToGraphMounts]);

  const refreshChart = () => {
    if (!chartRef.current || !dataToGraph) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, 'height'));

    const chart = new ApexCharts(chartRef.current, getChartOptions(height));

    chart.render();

    return chart;
  };




  const refreshChartMount = () => {
    if (!chartRefMounts.current || !dataToGraphMounts) {
      return;
    }

    const height = parseInt(getCSS(chartRefMounts.current, 'height'));


    const chartMounts = new ApexCharts(chartRefMounts.current, getChartOptionsMounts(height));

    chartMounts.render();

    return chartMounts;
  };


  console.log('TOTALES', dataToGraph)

  function getChartOptions(height: number): ApexOptions {
    const labelColor = getCSSVariableValue('--kt-gray-500');
    const borderColor = getCSSVariableValue('--kt-gray-200');
    const baseColor = getCSSVariableValue('--kt-primary');
    const secondaryColor = getCSSVariableValue('--kt-gray-300');

    console.log('TOTALES', dataToGraph)


    let updatedNames;

    let data = [];

    if (dataToGraph?.dataToGraph?.integratorCount) {
      data = dataToGraph.dataToGraph.integratorCount.map((item: { integratorName: any; transactionCount: string; }) => {
        return { x: item.integratorName, y: parseFloat(item.transactionCount) };
      });
    }
    
    console.log("data transformada", data);



    return {
      series: [{ name: "", data }],
      chart: {
        fontFamily: 'inherit',
        type: 'bar',
        height: height,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '40%',
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: updatedNames || [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
          formatter: (value) => {
            return Math.round(value).toString();  // Esto redondeará los valores a enteros
          }
        },
        decimalsInFloat: 0,
      },
      fill: {
        type: 'solid',
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val: any) {
            return `${val} Transacciones`;
          },
        },
        marker: {
          show: false,
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },

        },
        padding: {
          left: 20,
          right: 20,
        },

      },
      legend: {
        show: false,
      },
    };
  }


  function getChartOptionsMounts(height: number): ApexOptions {
    const labelColor = getCSSVariableValue('--kt-gray-500');
    const borderColor = getCSSVariableValue('--kt-gray-200');
    const baseColor = getCSSVariableValue('--kt-primary');
    const secondaryColor = getCSSVariableValue('--kt-gray-300');


    let updatedNames;




    console.log('mONTOS', dataToGraphMounts)

    let data: { x: string; y: number }[] = [];
    if (dataToGraphMounts?.dataToGraph?.integratorSums) {
      data = dataToGraphMounts.dataToGraph.integratorSums.map((item: { integratorName: any; totalAmount: string; }) => {
        return { x: item.integratorName, y: parseFloat(item.totalAmount) };
      });
    }




    return {
      series: [{ name: "", data }],
      chart: {
        fontFamily: 'inherit',
        type: 'bar',
        height: height,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '40%',
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: updatedNames || [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      fill: {
        type: 'solid',
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val: any) {
            return `$ ${formatTotal(val)}`;
          },
        },
        marker: {
          show: false,
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          left: 20,
          right: 20,
        },
      },
      legend: {
        show: false,
      },
    };
  }

  function formatTotal(total: any): string {
    const numTotal = parseFloat(total);

    if (Number.isNaN(numTotal)) {
      return '0.00';
    } else {
      return Number.isInteger(numTotal)
        ? new Intl.NumberFormat().format(numTotal) + '.00'
        : numTotal.toFixed(2);
    }
  }



  return (
    <div className="card">
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        {/* begin::Title */}
        <h2 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-1 mb-1 text-primary">Pagos por integrador Passport</span>
        </h2>
        {/* end::Title */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body">

        <div className="row">


          <div className="col-xl-6 col-lg-6 col-md-6">

            {isLoading || (dataToGraph?.dataToGraph?.total === undefined || isNaN(dataToGraph?.dataToGraph?.total)) ? (

              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <div style={{ flex: 1, marginRight: '1rem' }}>
                  <Skeleton
                    width={40}
                    height={200}
                    style={{
                      marginBottom: '8px',
                      borderRadius: '4px',

                    }}
                  />
                </div>

                <div style={{ flex: 1, marginRight: '1rem', marginLeft: '1rem' }}>
                  <Skeleton
                    width={40}
                    height={150}
                    style={{
                      marginBottom: '8px',
                      borderRadius: '4px',

                    }}
                  />
                </div>

                <div style={{ flex: 1, marginRight: '1rem', marginLeft: '1rem' }}>
                  <Skeleton
                    width={40}
                    height={100}
                    style={{
                      marginBottom: '8px',
                      borderRadius: '4px',

                    }}
                  />
                </div>
              </div>

            ) : (
              <>

                <h3 className="fs-3 mb-1">Transacciones por integrador Passport</h3>
                <div ref={chartRef} id="kt_charts_widget_1_chart" style={{ height: '350px' }} />
              </>
            )}




          </div>

          <div className="col-xl-6 col-lg-6 col-md-6">

            {isLoading || (dataToGraphMounts?.dataToGraph?.total === undefined || isNaN(dataToGraphMounts?.dataToGraph?.total)) ? (
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <div style={{ flex: 1, marginRight: '1rem' }}>
                  <Skeleton
                    width={40}
                    height={200}
                    style={{
                      marginBottom: '8px',
                      borderRadius: '4px',

                    }}
                  />
                </div>

                <div style={{ flex: 1, marginRight: '1rem', marginLeft: '1rem' }}>
                  <Skeleton
                    width={40}
                    height={150}
                    style={{
                      marginBottom: '8px',
                      borderRadius: '4px',

                    }}
                  />
                </div>

                <div style={{ flex: 1, marginRight: '1rem', marginLeft: '1rem' }}>
                  <Skeleton
                    width={40}
                    height={100}
                    style={{
                      marginBottom: '8px',
                      borderRadius: '4px',

                    }}
                  />
                </div>
              </div>

            ) : (
              <>

                <h3 className="fs-3 mb-1">Montos por integrador Passport</h3>
                <div ref={chartRefMounts} id="kt_charts_widget_2_chart" style={{ height: '350px' }} />
              </>
            )}
          </div>




          <div className="row mt-20">
            <div className="col-xl-6 col-lg-6 col-md-6">

              {isLoading || (dataToGraph?.dataToGraph?.total === undefined || isNaN(dataToGraph?.dataToGraph?.total)) ? (

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {/* Simulate an icon */}
                  <Skeleton
                    width={30}
                    height={30}
                    style={{
                      marginRight: '10px',
                      borderRadius: '50%',

                    }}
                  />

                  {/* Simulate a legend */}
                  <Skeleton
                    width={100}
                    height={20}
                    style={{
                      borderRadius: '4px',

                    }}
                  />
                </div>

              ) : (

                <div>
                  <div className="d-flex align-items-center mr-10">
                    <span className="fs-3hx fw-bold text-dark me-2 lh-1 ls-n2">
                      <svg xmlns="http://www.w3.org/2000/svg" height="1.3em" className="my-svg-icon" viewBox="0 0 512 512"><path d="M511.1 378.8l-26.7-160c-2.6-15.4-15.9-26.7-31.6-26.7H208v-64h96c8.8 0 16-7.2 16-16V16c0-8.8-7.2-16-16-16H48c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16h96v64H59.1c-15.6 0-29 11.3-31.6 26.7L.8 378.7c-.6 3.5-.9 7-.9 10.5V480c0 17.7 14.3 32 32 32h448c17.7 0 32-14.3 32-32v-90.7c.1-3.5-.2-7-.8-10.5zM280 248c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16v-16zm-32 64h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16v-16c0-8.8 7.2-16 16-16zm-32-80c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16v-16c0-8.8 7.2-16 16-16h16zM80 80V48h192v32H80zm40 200h-16c-8.8 0-16-7.2-16-16v-16c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16zm16 64v-16c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16zm216 112c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h176c4.4 0 8 3.6 8 8v16zm24-112c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16v-16c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16zm48-80c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16v-16c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16z" /></svg>
                    </span>
                    <div className="d-flex flex-column align-items-start" style={{ marginLeft: "20px" }}>


                      <div className="text flex-grow-1 mb-1" style={{ color: "black", fontSize: "2.7em" }}><strong>
                        {
                          isNaN(dataToGraph?.dataToGraph?.total)
                            ? 0
                            : new Intl.NumberFormat().format(dataToGraph?.dataToGraph?.total) || 0
                        }


                      </strong></div>
                      <div className="text-gray-500 flex-grow-1 me-3 fs-8">Transacciones totales </div>
                    </div>

                  </div>

                </div>
              )}

            </div>


            <div className="col-xl-6 col-lg-6 col-md-6">


              {isLoading || (dataToGraphMounts?.dataToGraph?.total === undefined || isNaN(dataToGraphMounts?.dataToGraph?.total)) ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {/* Simulate an icon */}
                  <Skeleton
                    width={30}
                    height={30}
                    style={{
                      marginRight: '10px',
                      borderRadius: '50%',

                    }}
                  />

                  {/* Simulate a legend */}
                  <Skeleton
                    width={100}
                    height={20}
                    style={{
                      borderRadius: '4px',

                    }}
                  />
                </div>

              ) : (
                <div>
                  <div className="d-flex align-items-center mr-10">
                    <span className="fs-3hx fw-bold text-dark me-2 lh-1 ls-n2">
                      <svg xmlns="http://www.w3.org/2000/svg" height="1.3em" className="my-svg-icon" viewBox="0 0 576 512"><path d="M0 432c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V256H0v176zm192-68c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-40zm-128 0c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM576 80v48H0V80c0-26.5 21.5-48 48-48h480c26.5 0 48 21.5 48 48z" /></svg>
                    </span>
                    <div className="d-flex flex-column align-items-start" style={{ marginLeft: "20px" }}>
                      <div className="text flex-grow-1 mb-1" style={{ color: "black", fontSize: "2.7em" }}><strong>$




                        {
                          formatTotal(dataToGraphMounts?.dataToGraph?.total)
                        }


                      </strong></div>
                      <div className="text-gray-500 flex-grow-1 me-3 fs-8">Monto Total</div>
                    </div>

                  </div>

                </div>
              )}
            </div>


          </div>

        </div>
      </div>
      {/* end::Body */}
    </div>
  );
};

export { ChartsIntegrators };
