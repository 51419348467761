import { FC, useEffect, useState } from 'react'

import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'


import moment from 'moment';
import { fetchLastReport, fetchCustomers, fetchDetailCustomer, saveInfoReport } from './apis/requestsApi'

import { ICustomer, ICustomerDetail } from './core/Report.Model'
import { Dropdown } from 'react-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2'

import { ChartsValidateIdentity } from './validateIdentity/chartsValidateIdentity';
import { ChartsPayments } from './payments/chartsPayments'; 
import { ChartsScore } from './score/chartsScore';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ChartsIntegrators } from './integrators/chartsIntegratos';

const DashboardWrapper: FC = () => {
  const [customers, setCustomers] = useState<ICustomer[]>([])

  const [customer, setCustomer] = useState<ICustomerDetail>()
  const [selectedCustomer, setSelectedCustomer] = useState('global')

  const [selectedRange, setSelectedRange] = useState('');
  const [selectedReport, setSelectedReport] = useState('');
  const [timezone, setTimezone] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [lastReport, setLastReport] = useState('')
  const [createdAt, setCreatedAt] = useState<Date>(new Date());

  const [loadingCustomer, setLoadingCustomer] = useState(false) // Nuevo estado para controlar la carga del cliente seleccionado


  const options = [
    { value: "", label: "Seleccionar" },
    { value: "ValidacionIdentidad", label: "Generar Validación de identidad" },
    { value: "ScoreCrediticio", label: "Generar Score crediticio" },
    { value: "Pagos", label: "Generar Pago de servicios" },
    { value: "Integrator", label: "Generar Integradores Passport" },
  ];



  interface CustomSweetAlertOptions extends SweetAlertOptions {
    onOpen?: () => void
  }


  const handleDownload = async () => {
    try {

      Swal.fire({
        html: `
        <div class="spinner-container" style="overflow: hidden;>
            <i class="fas fa-spinner fa-spin"></i>
            <span>Generando reporte...</span>
          </div>
        `,
        showConfirmButton: false,
        allowOutsideClick: false,
      } as CustomSweetAlertOptions)




      const link = document.createElement('a')
      link.href = lastReport
      link.download = 'consumption report.csv'
      link.click()


      Swal.close()
    } catch (err: any) {
      Swal.close()
      console.error(err)
    }
  }

  useEffect(() => {
    const getTimezone = () => {
      if (typeof Intl !== 'undefined' && typeof Intl.DateTimeFormat !== 'undefined') {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setTimezone(userTimezone);


      } else {
        // Si no se puede obtener la zona horaria automáticamente, se puede establecer un valor predeterminado o dejarla en blanco
        setTimezone('');
      }
    };

    getTimezone();
  }, []);




  useEffect(() => {
    const fetchData = async () => {
    
      try {

        const { customers } = await fetchCustomers();
        const { lastReport, createdAt } = await fetchLastReport();

        if (lastReport) {


          setLastReport(lastReport)
          setCreatedAt(createdAt)



        }


        setCustomers(customers);
      } catch (err: any) {
        console.log(err)
      }
    };
    fetchData();
  }, []); // Arreglo de dependencias vacío

  useEffect(() => {
    const fetchData = async () => {
    
      try {
        setLoadingCustomer(true); // Inicia la carga del cliente seleccionado
        const { customer } = await fetchDetailCustomer(selectedCustomer);
        setCustomer(customer);
      } catch (err: any) {
        console.log(err)
      } finally {
        setLoadingCustomer(false); // Finaliza la carga del cliente seleccionado
      }
    };
    fetchData();
  }, [selectedCustomer]);



  const handleGenerateReport = () => {


    if (selectedRange === '') {
      // No se seleccionó ningún reporte
      Swal.fire('Error', 'Por favor, seleccione un rango de fechas', 'error');
      setSelectedReport('')

      return;
    }

    if (selectedRange === 'custom') {

      if (startDate === '' || endDate === '') {
        // No se seleccionó ningún reporte
        setSelectedReport('')
        Swal.fire('Error', 'Por favor, seleccione tanto la fecha de inicio como la fecha de fin', 'error');

        return;
      }
    }

    if (selectedCustomer === '') {
      // No se seleccionó ningún reporte
      Swal.fire('Error', 'Por favor, seleccione un cliente', 'error');
      setSelectedReport('')
      return;
    }

    // Obtener la leyenda del periodo y el tipo de reporte
    let periodLabel = '';
    switch (selectedRange) {
      case 'cumulative':
        periodLabel = 'Acumulado hasta la fecha';
        break;
      case 'last24h':
        periodLabel = 'Últimas 24h';
        break;
      case 'lastWeek':
        periodLabel = 'Última semana';
        break;
      case 'lastMonth':
        periodLabel = 'Último mes';
        break;
      case 'custom':
        periodLabel = `Rango de fechas: ${startDate} - ${endDate}`;
        break;
      default:
        periodLabel = '';
    }

    let reportLabel = '';
    switch (selectedReport) {
      case 'ValidacionIdentidad':
        reportLabel = 'Validación de identidad';
        break;
      case 'ScoreCrediticio':
        reportLabel = 'Score crediticio';
        break;
      case 'Pagos':
        reportLabel = 'Pago de servicios';
        break;

    }

    // Mostrar SweetAlert para confirmar la generación del reporte
    Swal.fire({
      title: 'Confirmar Generación de Reporte',
      html: `
        <div>
        <p><strong>Tipo de Reporte:</strong></p>
        <p>${reportLabel}</p>
        <p><strong>Cliente:</strong></p>
        
        <p>${customer?.name ? customer.name : "Global"}</p>

        <p><strong>Período seleccionado:</strong></p>
        <p>${periodLabel}</p>
      </div>
      `,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Generar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        // Lógica para generar el reporte seleccionado

        if (result.isConfirmed) {

          Swal.fire({
            title: 'Enviando Solicitud',
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          const reportParams = {
            customer: selectedCustomer,
            typeReport: selectedReport,
            selectedRange: selectedRange,
            startDate: startDate,
            endDate: endDate,
            timezone: 'America/Monterrey'
          };

          saveInfoReport(reportParams)
            .then((response) => {


              // Solicitud exitosa
              setSelectedReport('')
              Swal.close(); // Cerrar el SweetAlert de carga
              Swal.fire('Solicitud Enviada', 'Se ha enviado la solicitud de generación del reporte', 'info');
              // Hacer algo con la respuesta si es necesario
            })
            .catch((error) => {
              // Error en la solicitud
              setSelectedReport('')
              Swal.close(); // Cerrar el SweetAlert de carga
              Swal.fire('Error', 'Ha ocurrido un error al enviar la solicitud del reporte', 'error');
              // Manejar el error si es necesario
            });
        }

      }
    });
  };
  useEffect(() => {

    if (selectedReport != '') {
      handleGenerateReport(); // Llama a la función cada vez que selectedReport cambie
    }

  }, [selectedReport]);

  return (
    <>
      <div className="row align-items-stretch px-5">

        {loadingCustomer ? (
          <>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="symbol symbol-100px symbol-lg-160px symbol-fixed" style={{ marginRight: "10px" }}>
                      <Skeleton circle={true} height={100} width={100} /> {/* Imagen del cliente */}
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <Skeleton count={1} height={30} /> {/* Nombre del cliente */}
                    <Skeleton count={1} height={20} /> {/* Teléfono del cliente */}
                    <Skeleton count={1} height={20} /> {/* Correo electrónico del cliente */}
                    <Skeleton count={1} height={20} /> {/* Dirección fiscal del cliente */}
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <Skeleton count={1} height={100} /> {/* Nombre del cliente */}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (

          <>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 d-flex flex-column ">
              {/* Customer Information */}
              <div className="card ">
                <div className="card-body">
                  <div className="row">
                    {/* Customer Avatar */}
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-8">
                      <div className="d-flex flex-column flex-md-row ">
                        {/* Customer Avatar Image */}


                        <div className=" symbol symbol-100px symbol-lg-160px  mb-3 mb-md-0 me-md-3 d-flex justify-content-center align-items-center">
                          <img
                            src={customer?.photo || toAbsoluteUrl('/media/avatars/blank.png')}
                            alt={customer?.name}
                            onError={(e) => {
                              e.currentTarget.onerror = null;
                              e.currentTarget.src = toAbsoluteUrl('/media/avatars/blank.png');
                            }}
                            className="img-fluid"
                            style={{ maxWidth: "160px" }}
                          />
                        </div>
                        {/* Customer Information */}
                        <div>
                          <div className="mt-4 mb-2">
                            {selectedCustomer === 'global' ? (
                              <span className="text-gray-800 fs-2 fw-bolder me-1">Reporte Global</span>
                            ) : (
                              <>
                                <div className="d-flex align-items-center">
                                  <span className="text-black-800 fs-3"><strong>{customer?.name_manager}</strong></span>
                                  <span className="text-black-800 mx-2 fs-3"><strong>-</strong></span>
                                  <span className="text-black-800 fs-3"><strong>{customer?.name}</strong></span>
                                </div>

                              </>
                            )}
                          </div>

                          {/* Customer Contact Information */}
                          <div className="row my-2">
                            <div className={`col${selectedCustomer === 'global' ? '' : '-12 col-sm-6'}`}>
                              {selectedCustomer === 'global' ? (
                                <span className="d-flex align-items-center text-gray-400">Reporte Global</span>
                              ) : (
                                <div className="d-flex">
                                  <KTSVG path="/media/icons/duotune/communication/com013.svg" className="svg-icon-4 me-2" />
                                  <span className="text-gray-400">{customer?.phone}</span>
                                </div>
                              )}
                            </div>
                            {selectedCustomer !== 'global' && (
                              <div className="col-12 col-sm-6">
                                <div className="d-flex">
                                  <KTSVG path="/media/icons/duotune/communication/com002.svg" className="svg-icon-4 me-2" />
                                  <span className="text-gray-400">{customer?.email}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* Customer Fiscal Address */}
                          <div className="d-flex align-items-center">
                            {selectedCustomer === 'global' ? (
                              <span className="d-flex align-items-center text-gray-400">Reporte Global</span>
                            ) : (
                              <>
                                <div className="d-flex align-items-center">
                                  <KTSVG path="/media/icons/duotune/general/gen018.svg" className="svg-icon-4 me-2" />
                                  <span className="text-gray-400">{customer?.fiscal_address}</span>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                    </div>

                    {/* Report Filters */}
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-4 mt-2">
                      <div className="row">
                        {/* Customer Dropdown */}
                        <div className="col-md-12">
                          <div className="d-flex justify-content-center">
                            <div className="w-100 text-center">
                              <select
                                data-control="select2"
                                data-hide-search="true"
                                className="form-select form-select-sm form-select-white custom-select"
                                value={selectedCustomer}
                                onChange={(e) => setSelectedCustomer(e.target.value)}
                              >
                                <option value="global">Reporte Global</option>
                                {customers.map((customer) => (
                                  <option key={customer.id} value={customer.id}>
                                    {customer.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Date Range Dropdown */}
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div className="d-flex justify-content-center">
                            <div className="w-100 text-center">
                              <select
                                name="dateRange"
                                value={selectedRange}
                                className="form-select form-select-sm form-select-white custom-select"
                                onChange={(e) => setSelectedRange(e.target.value)}
                              >
                                <option value="">Seleccionar</option>
                                <option value="cumulative">Acumulado hasta la fecha</option>
                                <option value="last24h">Últimas 24h</option>
                                <option value="lastWeek">Última semana</option>
                                <option value="lastMonth">Último mes</option>
                                <option value="custom">Rango de fechas</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Custom Date Range */}
                      {selectedRange === 'custom' && (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="d-flex justify-content-center mt-3">
                              <div className="w-100 text-start">
                                <label htmlFor="startDate" className="form-label">Fecha inicio</label>
                                <input
                                  type="date"
                                  id="startDate"
                                  name="startDate"
                                  className="form-control"
                                  value={startDate}
                                  onChange={(e) => setStartDate(e.target.value)}
                                />
                              </div>
                              <div className="w-100 text-start">
                                <label htmlFor="endDate" className="form-label">Fecha fin</label>
                                <input
                                  type="date"
                                  id="endDate"
                                  name="endDate"
                                  className="form-control"
                                  value={endDate}
                                  onChange={(e) => setEndDate(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Consolidated Consumption */}
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4  d-flex flex-column">
              <div className="row-md-12 h-100">

                <div className="card  custom-color-card h-100">
                  <div className="card-body d-flex flex-column align-items-center justify-content-center">
                    <h4 className="card-title text-white">CONSOLIDADO DE CONSUMO</h4>
                    <div className="d-flex justify-content-center mt-1">
                      <div className="w-100 text-center">
                        <Dropdown>
                          <Dropdown.Toggle className=" btn-primary btn btn-secondary">
                            {selectedReport ? options.find(option => option.value === selectedReport)?.label : "Seleccionar"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {options.map(option => (
                              <Dropdown.Item
                                key={option.value}
                                onClick={() => setSelectedReport(option.value)}
                              >
                                {option.label}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div className="w-100 text-center ">
                      <button onClick={handleDownload} className="btn btn-link link-white text-white text-decoration-underline mt-1">
                        <br />
                        {lastReport !== '' ? 'Descargar último Reporte' : 'No hay reporte disponible'}
                        <br />
                        {lastReport ? moment.utc(createdAt).local().format('YYYY-MM-DD HH:mm:ss') : ''}
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </>
        )}

      </div>







      <div className="row mt-7 px-8">

        <ChartsValidateIdentity
          selectedCustomer={selectedCustomer}
          selectedRange={selectedRange}
          startDate={startDate}
          endDate={endDate}
          timezone={timezone}

        />


      </div>

      <div className="row mt-7 px-8">
        <ChartsPayments
          selectedCustomer={selectedCustomer}
          selectedRange={selectedRange}
          startDate={startDate}
          endDate={endDate}
          timezone={timezone}

        />
      </div>

      <div className="row mt-7 px-8">
        <ChartsScore
          selectedCustomer={selectedCustomer}
          selectedRange={selectedRange}
          startDate={startDate}
          endDate={endDate}
          timezone={timezone}

        />
      </div>

      <div className="row mt-7 px-8">
        <ChartsIntegrators
          selectedCustomer={selectedCustomer}
          selectedRange={selectedRange}
          startDate={startDate}
          endDate={endDate}
          timezone={timezone}

        />
      </div>

    </>

  );
};

export { DashboardWrapper };
