import React, { useState, useEffect } from 'react';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { fetchReferencesDetail } from './apis/referencesApi';
import axios from 'axios';
import Swal from 'sweetalert2';
import { getTokenConfig } from '../../../../src/auth';
import { Request } from './core/References.Model';

const ReferenceDetail: React.FC = () => {
  const { reference, customerId } = useParams<{ reference: string; customerId: string }>();
  const [requests, setRequests] = useState<Array<Request>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const intl = useIntl();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = await getTokenConfig();

        if (reference) {
          const { requests, totalRegisters } = await fetchReferencesDetail(config, reference);
          setRequests(requests);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        console.error('Error fetching data:', err);
        // Manejo del error, por ejemplo mostrar un mensaje de error con Swal
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Hubo un error al cargar los detalles de la referencia.',
        });
      }
    };

    fetchData();
  }, [reference]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <p>Cargando...</p>
      </div>
    );
  }

  const renderFormattedContent = (content: string) => {
    try {
      const parsedContent = JSON.parse(content);

      // Si se puede parsear como JSON, entonces es JSON
      return (
        <pre>
          {JSON.stringify(parsedContent, null, 4)}
        </pre>
      );
    } catch (error) {
      // Si no se puede parsear como JSON, entonces asumimos que es XML
      return (
        <pre>
          {content}
        </pre>
      );
    }
  };

  const userBreadCrumbs: Array<PageLink> = [
    {
      title: 'Referencias',
      path: `/references/list/${customerId}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
    <>
      <PageTitle breadcrumbs={userBreadCrumbs} />
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>
                  {intl.formatMessage({ id: 'SERVICES.REFERENCE_DETAILS' })}
                </h3>
              </div>
            </div>

            <div className='card-body p-9'>
              <div className='row mb-5'>
                <div className='col-3 d-flex align-items-start flex-wrap'>
                  <div className='w-100'>
                    <label className='form-label'>
                      <b>Usuario:</b>
                    </label>
                    <p> {requests.length > 0 ? requests[0].userName : ''}</p>
                  </div>
                </div>
              </div>

              <div className='row mt-5'>
                <div className='col-12'>
                  <div className='table-responsive mb-3'>
                    <table className='table table-striped' id='table'>
                      <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                          <th className='min-w-50px'>#</th>
                          <th className='min-w-150px'>Fecha</th>
                          <th className='min-w-50px'>Hora</th>
                          <th className='min-w-150px'>Mensaje</th>
                          <th className='min-w-150px'>Cadena</th>
                          <th className='min-w-150px'>Resultado</th>
                          <th className='text-center'>
                            {intl.formatMessage({ id: 'USERS.ACTION' })}
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {requests.map((request, index) => (
                          <tr key={index}>
                            <td>{request.id}</td>
                            <td> {new Date(request.createdAt).toLocaleDateString("es-ES")}</td>
                            <td>{new Date(request.createdAt).toLocaleTimeString("es-ES", { hour: 'numeric', minute: 'numeric', second: 'numeric' })}</td>

                            <td>
                              {(() => {
                                switch (request.typeRequest) {
                                  case 1:
                                    return 'Referencia generada';
                                  case 2:
                                    return 'Consulta';
                                  case 3:
                                    return 'Pago';
                                  case 4:
                                    return 'Reverso';
                                  default:
                                    return '';
                                }
                              })()}
                            </td>

                            <td>{request.center}</td>
                            <td>
                              {Number(request.statusCode) === 200 ? (
                                <span
                                  className='badge rounded-pill text-bg-success text-center text-white'
                                  style={{ width: '80px', display: 'block' }}
                                >
                                  {request.statusCode}
                                </span>
                              ) : (
                                <span
                                  className='badge rounded-pill text-bg-danger text-center text-white'
                                  style={{ width: '80px', display: 'block' }}
                                >
                                  {request.statusCode}
                                </span>
                              )}
                            </td>
                            <td className='text-center'>
                              <button
                                type='button'
                                className='dropdown-toggle icon-dropdown'
                                data-bs-toggle='modal'
                                data-bs-target={`#requestModal-${request.id}`}
                              >
                                <i className='bi bi-eye-fill'></i>
                              </button>

                              <div
                                className='modal fade '
                                id={`requestModal-${request.id}`}
                                tabIndex={-1}
                                aria-labelledby='exampleModalLabel'
                                aria-hidden='true'
                              >
                                <div className='modal-dialog modal-dialog-centered modal-lg'>
                                  <div className='modal-content'>
                                    <div className='modal-header'>
                                      <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                                        Detalle
                                      </h1>
                                      <button
                                        type='button'
                                        className='btn-close'
                                        data-bs-dismiss='modal'
                                        aria-label='Close'
                                      ></button>
                                    </div>
                                    <div className='modal-body'>
                                      <p className='text-start ms-5'><strong> Request</strong></p>
                                      <div className='text-start ms-5'>
                                        {renderFormattedContent(request.providerRequest)}
                                      </div>

                                      <p className='text-start ms-5'><strong> Response</strong></p>
                                      <div className='text-start ms-5'>
                                        {renderFormattedContent(request.providerResponse)}
                                      </div>
                                    </div>
                                    <div className='modal-footer'>
                                      <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                                        Cerrar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ReferenceDetail };
