/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'

import Logout from '../../../../app/modules/auth/core/Logout'

import { toAbsoluteUrl } from '../../../helpers'

import { useIntl } from 'react-intl'

interface User {
  id: string;
  email: string;
  first_name: string;
  first_surname: string;
  second_surname: string;
  phone: string;
  photo: string;
  timezone:string;
  group: {
    id: string;
    name: string;
  };

}


interface Props {
  user: User | null;
  timezoneOffset: string | '';
  currentTime: string | '';
}


const HeaderUserMenu: FC<Props> = (props) => {

  const { user,timezoneOffset, currentTime } = props;


  const handleClick = Logout();
  const intl = useIntl();





  return (

    <div
      className='sub-sub menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo'
              src={user?.photo || toAbsoluteUrl('/media/avatars/blank.png')}
              style={{ maxWidth: '800px', height: 'auto', display: 'block', margin: '0 auto' }}
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = toAbsoluteUrl('/media/avatars/blank.png');
              }} />
          </div>


          <div className='d-flex flex-column'>
            <div className='d-flex flex-column user-sub'>
              <small>Usuario</small>
              <span>{user?.first_name} {user?.first_surname} {user?.second_surname}</span>
            </div>
            <div className='d-flex flex-column user-sub'>
              <small>Zona Horaria</small>
              <span>{user?.timezone || 'Zona horaria no definida'} UTC{timezoneOffset} {currentTime}</span>
            </div>
          </div>

          


         
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className=''>
        <Link to={"/users/profile"} className='menu-link px-5'>
          {intl.formatMessage({ id: 'SHOW.PROFILE' })}
        </Link>
      </div>


      <div className='separator my-2'></div>

      <div className=''>
        <Link to={"/users/timezone"} className='menu-link px-5'>
          {intl.formatMessage({ id: 'CONFIG.TIMEZONE' })}
        </Link>
      </div>


      <div className='separator my-2'></div>


      {/*
      <Languages />
      
      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div>
  */}

      <div className=''>
        <a onClick={handleClick} className='menu-link px-5'>
          Cerrar sesión
        </a>
      </div>

    </div>
  )
}

export { HeaderUserMenu }
