
import { FC, useEffect, useRef, useState } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { KTSVG } from '../../../../_metronic/helpers';
import { getCSS, getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils';
import { fetchDataToGraph, fetchDataToGraphTable } from '../apis/requestsApi';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

interface ChartsValidateIdentityProps {
  selectedCustomer: string;
  selectedRange: string;
  startDate: string;
  endDate: string;
  timezone: string;
}

const ChartsValidateIdentity: FC<ChartsValidateIdentityProps> = (props) => {
  const { selectedCustomer, selectedRange, startDate, endDate, timezone } = props;
  const [isLoading, setIsLoading] = useState(true); // <- Agregar esto
  const chartRef = useRef<HTMLDivElement | null>(null);
  const circleChartRef = useRef<HTMLDivElement | null>(null);
  const [dataToGraph, setDataToGraph] = useState<any>(null);
  const [dataToGraphTable, setDataToGraphTable] = useState<any>(null);
  const [dataToGraphTotal, setDataToGraphTotal] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(0);


  // Calcula el índice de inicio y fin para la página actual
  const startIndex = currentPage * 15;
  const endIndex = startIndex + 15;

  const rowsForCurrentPage = dataToGraphTable?.dataToGraph?.rows?.slice(startIndex, endIndex);
  // Calcula el número total de páginas
  const totalPages = Math.ceil(dataToGraphTable?.dataToGraph?.rows?.length / 15);

  // Funciones para cambiar de página
  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    const fetchDataToGraphF = async () => {
      setIsLoading(true); // <- Agregar esto
      try {
        const reportParams = {
          customer: selectedCustomer,
          selectedRange: selectedRange,
          startDate: startDate,
          endDate: endDate,
          timezone: timezone,
          typeReport: 'ValidacionIdentidad',
          view: 'graph1',
        };

        const reportParamsTable = {
          customer: selectedCustomer,
          selectedRange: selectedRange,
          startDate: startDate,
          endDate: endDate,
          timezone: timezone,
          typeReport: 'ValidacionIdentidad',
          view: 'requestList',
        };

        const reportParamsTotal = {
          customer: selectedCustomer,
          selectedRange: selectedRange,
          startDate: startDate,
          endDate: endDate,
          timezone: timezone,
          typeReport: 'ValidacionIdentidad',
          view: 'totalrequest',
        };

        const data = await fetchDataToGraph(reportParams);
        const dataTable = await fetchDataToGraphTable(reportParamsTable);
        const dataTotal = await fetchDataToGraphTable(reportParamsTotal);

        setDataToGraph(data);
        setDataToGraphTable(dataTable);
        setDataToGraphTotal(dataTotal);


      } catch (error) {
        console.error('Error al obtener los datos:', error);
      } finally {
        setIsLoading(false)
      }
    };

    fetchDataToGraphF();



  }, [selectedCustomer, selectedRange, startDate, endDate, timezone]);

  useEffect(() => {
    const chart = refreshChart();
    refreshCircleChart();

    return () => {
      if (chart) {
        chart.destroy();
      }

    };


  }, [chartRef, circleChartRef, dataToGraph, dataToGraphTotal]);

  const refreshChart = () => {


    if (!chartRef.current || !dataToGraph) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, 'height'));

    const chart = new ApexCharts(chartRef.current, getChartOptions(height));
    chart.render();

    return chart;
  };

  const refreshCircleChart = () => {
    if (!chartRef.current) {
      return
    }

    setTimeout(() => {
      getCircleChart()
    }, 10)
  };

  function getChartOptions(height: number): ApexOptions {
    const labelColor = getCSSVariableValue('--kt-gray-500');
    const borderColor = getCSSVariableValue('--kt-gray-200');
    const baseColor = getCSSVariableValue('--kt-primary');
    const secondaryColor = getCSSVariableValue('--kt-secondary');

    const webData = [
      dataToGraph?.dataToGraph?.totalWebNubarium || 0,
      dataToGraph?.dataToGraph?.totalWebSeon || 0,
      dataToGraph?.dataToGraph?.totalWebTruid || 0,
    ];

    const apiData = [
      dataToGraph?.dataToGraph?.totalApiNubarium || 0,
      dataToGraph?.dataToGraph?.totalApiSeon || 0,
      dataToGraph?.dataToGraph?.totalApiTruid || 0,
    ];

    console.log(apiData)
    console.log(webData)

    return {
      series: [
        {
          name: 'WEB',
          data: webData,
        },
        {
          name: 'API',
          data: apiData,
        },
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'bar',
        height: height,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30%',
          borderRadius: 5,
        },
      },
      legend: {
        show: true,
        position: 'right',
        offsetX: -10,
        offsetY: -25,
        floating: true,
        fontSize: '12px',
        fontFamily: 'Arial, sans-serif',
        width: 160,
        itemMargin: {
          horizontal: 0, // Alinear las leyendas en línea
          vertical: 3,
        },
        markers: {
          width: 10,
          height: 10,
          radius: 50,
        },
        horizontalAlign: 'left', // Alinear las leyendas en línea en el centro

      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: ['Nubarium', 'SEON', 'Tru id'],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return val.toLocaleString() + ' Solicitudes';
          },
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };
  }

  const getCircleChart = function () {
    const chartSize: number = 150;
    const chartLine: number = 24;
    const chartRotate: number = 125;
    const el = document.getElementById('kt_card_widget_17_chart')

    if (!el) {
      return
    }
    el.innerHTML = ''

    var options = {
      size: chartSize,
      lineWidth: chartLine,
      rotate: chartRotate,
    }

    const canvas = document.createElement('canvas')
    const span = document.createElement('span')

    // @ts-ignore
    if (typeof G_vmlCanvasManager !== 'undefined') {
      // @ts-ignore
      G_vmlCanvasManager.initElement(canvas)
    }

    const ctx = canvas.getContext('2d')
    canvas.width = canvas.height = options.size

    el.appendChild(span)
    el.appendChild(canvas)
    // @ts-ignore
    ctx.translate(options.size / 2, options.size / 2) // change center
    // @ts-ignore
    ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

    const radius = (options.size - options.lineWidth) / 2

    let previousPercent = 0;
    const drawCircle = function (color: string, lineWidth: number, percent: number) {
      percent = Math.min(Math.max(0, percent || 1), 1)
      if (!ctx) {
        return
      }

      let start = previousPercent * 2 * Math.PI;
      let end = (previousPercent + percent) * 2 * Math.PI;

      ctx.beginPath();
      ctx.arc(0, 0, radius, start, end, false);
      ctx.strokeStyle = color;
      ctx.lineCap = 'round'; // butt, round or square
      ctx.lineWidth = lineWidth;
      ctx.stroke();

      previousPercent += percent;
    }

    const totalSolicitudes = dataToGraphTotal?.dataToGraph?.total || 0;
    const totalSolicitudesWEB = dataToGraphTotal?.dataToGraph?.totalWeb || 0;
    const totalSolicitudesAPI = dataToGraphTotal?.dataToGraph?.totalApi || 0;

    // Calcular porcentajes
    const percentWeb = totalSolicitudesWEB / totalSolicitudes
    const percentAPI = totalSolicitudesAPI / totalSolicitudes

    console.log("Total solicitudes:", totalSolicitudes);
    console.log("Porcentaje WEB:", percentWeb);
    console.log("Porcentaje API:", percentAPI);

    // Dibujar círculos
    // Dibujar círculos
    drawCircle('#E4E6EF', options.lineWidth, 1); // draw background circle
    if (percentWeb > 0) {
      drawCircle(getCSSVariableValue('--kt-primary'), options.lineWidth, percentWeb);
    }
    if (percentAPI > 0) {
      drawCircle(getCSSVariableValue('--kt-secondary'), options.lineWidth, percentAPI);
    }
  }


  return (
    <div className="card ml-0">
      {/* begin::Header */}
      <div className="card-header border-0 pt-5 ">
        {/* begin::Title */}
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-1 mb-1 text-primary">KYC</span>
        </h3>
        {/* end::Title */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6">
            <div className="card-title align-items-start flex-column">
              <h2 className="fs-3 mb-1">Solicitudes por socio tecnológico</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6">


            {isLoading || (dataToGraph?.dataToGraph?.totalWebNubarium === undefined || isNaN(dataToGraph?.dataToGraph?.totalWebNubarium)) ? (
              <>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <div style={{ flex: 1, marginRight: '1rem' }}>
                    <Skeleton
                      width={40}
                      height={200}
                      style={{
                        marginBottom: '8px',
                        borderRadius: '4px',
                     
                      }}
                    />
                  </div>

                  <div style={{ flex: 1, marginRight: '1rem', marginLeft: '1rem' }}>
                    <Skeleton
                      width={40}
                      height={150}
                      style={{
                        marginBottom: '8px',
                        borderRadius: '4px',
                     
                      }}
                    />
                  </div>

                  <div style={{ flex: 1, marginRight: '1rem', marginLeft: '1rem' }}>
                    <Skeleton
                      width={40}
                      height={100}
                      style={{
                        marginBottom: '8px',
                        borderRadius: '4px',
                     
                      }}
                    />
                  </div>
                </div></>

            ) : (
              <div>

                <div ref={chartRef} id="kt_charts_widget_1_chart" style={{ height: '350px' }} />
              </div>

            )}

            <div className="row mt-20">
              <div className="col-xl-6 col-lg-6 col-md-6 d-flex align-items-center">

                {isLoading || (dataToGraphTotal?.dataToGraph?.total === undefined || isNaN(dataToGraphTotal?.dataToGraph?.total)) ? (


                  <Skeleton
                    width={100}
                    height={20}
                    style={{
                      marginBottom: '8px',
                      borderRadius: '4px',
                   
                    }}
                  />
                ) : (
                  <div>
                    <div className="d-flex align-items-center">
                      <span className="fs-3hx fw-bold text-dark me-2 lh-1 ls-n2">
                        {dataToGraphTotal?.dataToGraph?.total?.toLocaleString() || 0}
                      </span>
                    </div>
                    <span className="text-gray-400 pt-1 fw-semibold fs-8">
                      Transacciones totales de KYC
                    </span>
                  </div>
                )}
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 pb-4 d-flex flex-wrap align-items-center justify-content-center">

                {isLoading || (dataToGraphTotal?.dataToGraph?.totalWeb === undefined || isNaN(dataToGraphTotal?.dataToGraph?.totalWeb)) ? (
                  // Skeleton placeholders
                  <div className="d-flex flex-column">
                    <Skeleton
                      width={100}
                      height={20}
                      style={{
                        marginBottom: '8px',
                        borderRadius: '4px',
                     
                      }}
                    />
                    <Skeleton
                      width={100}
                      height={20}
                      style={{
                        marginBottom: '8px',
                        borderRadius: '4px',
                     
                      }}
                    />
                  </div>
                ) : (
                  <>
                    <div className="d-flex justify-content-center align-items-center me-md-5 me-sm-5 pt-2">
                      <div id="kt_card_widget_17_chart" ref={circleChartRef}></div>
                    </div>
                    <div className="flex-column align-items-center mt-3 mt-md-0">
                      <div className="d-flex fw-semibold align-items-center mb-3">
                        <div className="bullet w-8px h-8px rounded-circle bg-primary me-3"></div>
                        <div className="text-gray-500">&nbsp;WEB&nbsp;</div>
                        <div className="fw-bolder text-gray-700 text-xxl-end">

                          {
                            isNaN(dataToGraphTotal?.dataToGraph?.totalWeb)
                              ? 0
                              : new Intl.NumberFormat().format(dataToGraphTotal?.dataToGraph?.totalWeb) || 0
                          }
                        </div>
                      </div>
                      <div className="d-flex fw-semibold align-items-center">
                        <div className="bullet w-8px h-8px rounded-circle bg-secondary me-3"></div>
                        <div className="text-gray-500">&nbsp;API&nbsp;</div>
                        <div className="fw-bolder text-gray-700 text-xxl-end">
                          {
                            isNaN(dataToGraphTotal?.dataToGraph?.totalApi)
                              ? 0
                              : new Intl.NumberFormat().format(dataToGraphTotal?.dataToGraph?.totalApi) || 0
                          }
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>


          </div>

          <div className="col-xs-6 col-lg-6 col-md-6 d-flex justify-content-center">
            <div className="table-container">
              <div className="d-flex justify-content-between align-items-center mb-3">
                {isLoading || !rowsForCurrentPage ? (

                  <Skeleton width={30} height={30} style={{ marginRight: '8px', borderRadius: '50%' }} />

                ) : (
                  <button
                    type="button"
                    className="btn btn-icon btn-square btn-primary me-2"
                    onClick={goToPreviousPage}
                    disabled={currentPage === 0}
                  >
                    <KTSVG path="/media/icons/duotune/arrows/arr021.svg" className="svg-icon-2" />
                  </button>
                )}
                {isLoading || !rowsForCurrentPage ? (
                  <div style={{ width: '100%' }}>
                    {[...Array(10)].map((_, i) => (
                      <div key={i} style={{ display: 'flex', marginBottom: '1rem' }}>
                        <div style={{ flex: 1, marginRight: '1rem' }}>
                          <Skeleton
                            width="100%"
                            height={20}
                            style={{
                              marginBottom: '8px',
                              borderRadius: '4px',
                           
                            }}
                          />
                        </div>
                        <div style={{ flex: 1, marginRight: '1rem', marginLeft: '1rem' }}>
                          <Skeleton
                            width="100%"
                            height={20}
                            style={{
                              marginBottom: '8px',
                              borderRadius: '4px',
                           
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                ) : (
                  <table className="table table-row-bordered table-bordered">
                    <thead>
                      <tr className="fw-bolder fs-6 text-gray-800 bg-light" style={{ height: '3rem' }}>
                        <td>Servicio</td>
                        <td>Solicitudes</td>
                      </tr>
                    </thead>

                    <tbody>
                      {rowsForCurrentPage?.map((row: any, index: number) => (
                        <tr key={index} style={{ height: '3rem' }}>
                          <td>{row.service}</td>
                          <td className="text-primary">
                            <div style={{ textAlign: 'right', paddingRight: '4rem' }}>
                              <strong>{row.total.toLocaleString()}</strong>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                {isLoading || !rowsForCurrentPage ? (

                  <Skeleton width={30} height={30} style={{ marginRight: '8px', borderRadius: '50%' }} />

                ) : (

                  <button
                    type="button"
                    className="btn btn-icon btn-square btn-primary"
                    onClick={goToNextPage}
                    disabled={currentPage === totalPages - 1}
                  >
                    <KTSVG path="/media/icons/duotune/arrows/arr024.svg" className="svg-icon-2" />
                  </button>
                )}
              </div>
            </div>
          </div>



        </div>
      </div>
      {/* end::Body */}
    </div>
  );
};

export { ChartsValidateIdentity };
