import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { getConfigAlertsMonitoring, updateConfigAlertsMonitoring } from './apis';
import { AlertConfig } from './models';

const MonitoringAlerts: React.FC = () => {
  const [monitoringConfig, setMonitoringConfig] = useState<AlertConfig>({
    emails: '',
    firstAlert: 0,
    secondAlert: 0,
    thirdAlert: 0,
    createdAt: null,
    updatedAt: null
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getConfigAlertsMonitoring();
        setMonitoringConfig(response.monitoringConfig);
      } catch (error) {
        console.error('Error fetching monitoreo configuration:', error);
        setError(error instanceof Error ? error.message : String(error));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setMonitoringConfig({ ...monitoringConfig, [name]: value });
  };

  const handleSaveConfiguration = async () => {
    Swal.fire({
      icon: 'info',
      title: 'Guardando configuración',
      text: 'Por favor, espera mientras se guarda la configuración...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });

    try {
      const { emails, firstAlert, secondAlert, thirdAlert } = monitoringConfig;
      const value = {
        emails,
        firstAlert,
        secondAlert,
        thirdAlert
      };

      const response = await updateConfigAlertsMonitoring(value);

      if (response) {
        Swal.fire({
          icon: 'success',
          timer: 3000,
          title: 'Configuración actualizada',
          text: 'La configuración de monitoreo se ha actualizado correctamente.',
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        setMonitoringConfig({ ...monitoringConfig, ...response }); // Actualizar el estado con la respuesta actualizada
      }
    } catch (error) {
      console.error('Error saving monitoreo configuration:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al guardar la configuración de monitoreo. Por favor, inténtalo de nuevo.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Ocurrió un error: {error}</div>;
  }

  return (
    <div className='card mb-5 mb-xl-10 mt-10' id='kt_profile_details_view'>
      <div className='card-header'>
        <h3 className='card-title'>Configurar alertas de créditos de monitoreo </h3>
      </div>
      <div className="card-body">
        <div className="row">
          <div className='col-md-12'>
            <div className='mb-3'>
              <label htmlFor='emailsAlerts' className='form-label'>Email(s)</label>
              <input
                type='text'
                className='form-control'
                placeholder='example@example.com,examp'
                id='emailsAlerts'
                name='emails'
                value={monitoringConfig.emails}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <div className='mb-3'>
              <label htmlFor='firstAlert' className='form-label'>Transacciones fallidas consecutivas para primera alerta</label>
              <input
                type='number'
                className='form-control'
                id='firstAlert'
                name='firstAlert'
                value={monitoringConfig.firstAlert}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='mb-3'>
              <label htmlFor='secondAlert' className='form-label'>Transacciones fallidas consecutivas para segunda alerta</label>
              <input
                type='number'
                className='form-control'
                id='secondAlert'
                name='secondAlert'
                value={monitoringConfig.secondAlert}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='mb-3'>
              <label htmlFor='thirdAlert' className='form-label'>Transacciones fallidas consecutivas para tercera alerta</label>
              <input
                type='number'
                className='form-control'
                id='thirdAlert'
                name='thirdAlert'
                value={monitoringConfig.thirdAlert}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer">
        <div className="row">
          <div className="col-md-9"></div>
          <div className="col-md-3 d-flex justify-content-end">
            <button className="btn btn-primary" onClick={handleSaveConfiguration} disabled={isLoading}>
              Guardar configuración
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { MonitoringAlerts };
