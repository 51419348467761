import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'

import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'

import ProductPage from '../modules/products/ProductPage'
import ServicePage from '../modules/services/ProductPage'
import ReferencePage from '../modules/references/ReferencePage'
import ReportPage from '../modules/reports/ReportPage'
import RolesPage from '../modules/roles/RolesPage'
import LogPage from '../modules/log/LogPage'
import ReportPageValidateIdentity from '../modules/reportsValidateIdentity/ReportPageValidateIdentity'
import ReportPageScore from '../modules/reportsScore/ReportPage'
import PassportPage from '../modules/passport/PassportPage'
import ReportPagePassport from '../modules/reportsPassport/ReportPagePassport'
import RechargeDescPage from '../modules/rechargesDesc/RechargeDescPage'
import ReportPageRecharge from '../modules/reportsRecharges/ReportPageRecharge'
import DashboardPage from '../pages/dashboard/DashboardPage'
import { CommissionPaymentService } from '../modules/commissionPaymentService/CommissionPaymentService'
import CommissionPaymentServicePage from '../modules/commissionPaymentService/CommissionPaymentServicePage'
import ReportPagePaymentService from '../modules/reportsPaymentService/ReportPagePaymentService'
import ReportPageRiskIndicators from '../modules/reportsRiskIndicators/ReportPageRiskIndicators'
import ReportPageCC from '../modules/reportsCC/ReportPageCC'
import ReportPageOdoo from '../modules/reportsOdoo/ReportPageOdoo'
import ReportPageEventJourney from '../modules/reportsEventsJourney/ReportPageEventJourney'
import ReportPageHBM from '../modules/reportsHBM/ReportPageHBM'
import ReportPageGeneracionDocumentos from '../modules/reportsGeneracionDocumentos/ReportPageGeneracionDocumentos'
import ReportPageSMS from '../modules/reportsSMS/ReportPageSMS'
import ReportPageMails from '../modules/reportsMails/ReportPageMails'

const PrivateRoutes = () => {
  const UserPage = lazy(() => import('../modules/users/UserPage'))
  const CustomerPage = lazy(() => import('../modules/customers/CustomerPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/blank' />} />
        {/* Pages */}

        <Route path="/blank" element={<></>} />


        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}



        <Route
          path='dashboard'
          element={
            <SuspensedView>
              <DashboardPage />
            </SuspensedView>
          }
        />
        {/* Lazy Modules */}
        <Route
          path='users/*'
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        />

        <Route
          path='customers/*'
          element={
            <SuspensedView>
              <CustomerPage />
            </SuspensedView>
          }
        />

        <Route
          path='products/*'
          element={
            <SuspensedView>
              <ProductPage />
            </SuspensedView>
          }
        />

        <Route
          path='services/*'
          element={
            <SuspensedView>
              <ServicePage />
            </SuspensedView>
          }
        />

        <Route
          path='references/*'
          element={
            <SuspensedView>
              <ReferencePage />
            </SuspensedView>
          }
        />

        <Route
          path='reports/*'
          element={
            <SuspensedView>
              <ReportPage />
            </SuspensedView>
          }
        />


        <Route
          path='passport-reports/*'
          element={
            <SuspensedView>
              <ReportPagePassport />
            </SuspensedView>
          }
        />


        <Route
          path='recharges-reports/*'
          element={
            <SuspensedView>
              <ReportPageRecharge />
            </SuspensedView>
          }
        />


        <Route
          path='payment-services-reports/*'
          element={
            <SuspensedView>
              <ReportPagePaymentService />
            </SuspensedView>
          }
        />


        <Route
          path='journey-event-reports/*'
          element={
            <SuspensedView>
              <ReportPageEventJourney />
            </SuspensedView>
          }
        />




        <Route
          path='recharges/*'
          element={
            <SuspensedView>
              <RechargeDescPage />
            </SuspensedView>
          }
        />

        <Route
          path='payment-services/*'
          element={
            <SuspensedView>
              <CommissionPaymentServicePage />
            </SuspensedView>
          }
        />





        <Route
          path='validate-identity-reports/*'
          element={
            <SuspensedView>
              <ReportPageValidateIdentity />
            </SuspensedView>
          }
        />

        <Route
          path='score-reports/*'
          element={
            <SuspensedView>
              <ReportPageScore />
            </SuspensedView>
          }
        />

        <Route
          path='cc-reports/*'
          element={
            <SuspensedView>
              <ReportPageCC />
            </SuspensedView>
          }
        />


        <Route
          path='odoo-reports/*'
          element={
            <SuspensedView>
              <ReportPageOdoo />
            </SuspensedView>
          }
        />

        <Route
          path='hbm-reports/*'
          element={
            <SuspensedView>
              <ReportPageHBM />
            </SuspensedView>
          }
        />


        <Route
          path='sms-reports/*'
          element={
            <SuspensedView>
              <ReportPageSMS />
            </SuspensedView>
          }
        />


        <Route
          path='generacion-documentos-reports/*'
          element={
            <SuspensedView>
              <ReportPageGeneracionDocumentos />
            </SuspensedView>
          }
        />



        <Route
          path='mails-reports/*'
          element={
            <SuspensedView>
              <ReportPageMails />
            </SuspensedView>
          }
        />

        <Route
          path='risk-indicators-reports/*'
          element={
            <SuspensedView>
              <ReportPageRiskIndicators />
            </SuspensedView>
          }
        />


        <Route
          path='groups/*'
          element={
            <SuspensedView>
              <RolesPage />
            </SuspensedView>
          }
        />

        <Route
          path='logs/*'
          element={
            <SuspensedView>
              <LogPage />
            </SuspensedView>
          }
        />

        <Route
          path='passport/*'
          element={
            <SuspensedView>
              <PassportPage />
            </SuspensedView>
          }
        />

        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
