import  { CognitoUserPool } from "amazon-cognito-identity-js"


const poolData = {
    UserPoolId: "us-west-2_MnKBulCMl",
    ClientId: "4h6h0sn466fko808a4purilgva", 
    
}


export default new CognitoUserPool(poolData);