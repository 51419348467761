import React, { useState, useEffect } from 'react'

import { useIntl } from 'react-intl'
import { getTokenConfig } from '../../../../src/auth'
import axios from 'axios'
import { IProvider, IService } from './Service.Model'
import Swal from 'sweetalert2'

const ServiceConfig: React.FC = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [providers, setProviders] = useState<IProvider[]>([])
  const [services, setServices] = useState<IService[]>([])

  const associatedProviders = [
    {
      companyName: "Walmart",
      providers: ["Pespay", "Enefevo"],
    },
    {
      companyName: "Farmacias del Ahorro",
      providers: ["Pespay", "Enefevo"],
    },
    {
      companyName: "Super Kiosko",
      providers: ["Pespay", "Enefevo", "Passport"],
    },
    {
      companyName: "Waldos",
      providers: ["Pespay", "Enefevo", "Passport"],
    },
    {
      companyName: "Oxxo",
      providers: ["Conekta"],
    },
    {
      companyName: "7 Eleven",
      providers: ["Enefevo"],
    },
    {
      companyName: "7 ELEVEN",
      providers: ["Enefevo"],
    },
    {
      companyName: "Tiendas Extra",
      providers: ["Enefevo"],
    },
    {
      companyName: "Grupo Regional de Farmacias",
      providers: ["Enefevo"],
    },
    {
      companyName: "Fundación Rafael Dondé",
      providers: ["Enefevo"],
    },
    {
      companyName: "Del Sol",
      providers: ["Enefevo"],
    },
    {
      companyName: "Woolworth",
      providers: ["Enefevo"],
    },
    {
      companyName: "Farmacias Benavides",
      providers: ["Enefevo"],
    },
    {
      companyName: "Farmacias Bazar",
      providers: ["Enefevo"],
    },
    {
      companyName: "Super Fasti",
      providers: ["Enefevo"],
    },
    {
      companyName: "Soriana",
      providers: ["Enefevo"],
    },
    {
      companyName: "Super San Francisco de Asis",
      providers: ["Enefevo"],
    },
    {
      companyName: "Super Willis",
      providers: ["Enefevo"],
    },
    {
      companyName: "Farmacias la Paz",
      providers: ["Enefevo"],
    },
    {
      companyName: "Passport",
      providers: ["Passport"],
  },
  ];

  useEffect(() => {
    const fetchData = async () => {
      let queryString = `${process.env.REACT_APP_API_URL}/services/config`
      const config = await getTokenConfig()
      axios
        .get(queryString, config)
        .then((res) => {
          setProviders(res.data.data.providers)
          setServices(res.data.data.services)
          setIsLoading(false)
        })
        .catch((err) => {
          setError(err)
          setIsLoading(false)
        })
    }
    fetchData()
  }, [])

  const handleUpdateServiceConfig = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)

    try {
      const serviceElements = Array.from((event.currentTarget as HTMLFormElement).elements).filter(
        (element) => (element as HTMLInputElement).name.startsWith('service-')
      ) as HTMLInputElement[]
      const providerIdElements = Array.from(
        (event.currentTarget as HTMLFormElement).elements
      ).filter((element) =>
        (element as HTMLInputElement).name.startsWith('provider-')
      ) as HTMLSelectElement[]
      const configByClientElements = Array.from(
        (event.currentTarget as HTMLFormElement).elements
      ).filter((element) =>
        (element as HTMLInputElement).name.startsWith('configByClient-')
      ) as HTMLInputElement[]
      const persistConfigElements = Array.from(
        (event.currentTarget as HTMLFormElement).elements
      ).filter((element) =>
        (element as HTMLInputElement).name.startsWith('persistConfig-')
      ) as HTMLInputElement[]
      const configByClient = configByClientElements.map((element) => element.checked)
      const persistConfig = persistConfigElements.map((element) => element.checked)
      const providerIds = providerIdElements.map((element) => parseInt(element.value, 10))
      if (providerIds.some((id) => id <= 0)) {
        Swal.fire({
          title: 'Error',
          text: 'Debes seleccionar un socio tecnológico para cada servicio antes de guardar la configuración.',
          icon: 'error',
        })
        return
      }

      const services = serviceElements.map((element) => parseInt(element.value, 10))

      const servicesJson = services.map((serviceId, index) => ({
        id: serviceId,
        providerId: providerIds[index],
        configByClient: configByClient[index],
        configPersistent: persistConfig[index],
      }))

      const data = {
        services: servicesJson,
      }

      const config = await getTokenConfig()
      const queryString = `${process.env.REACT_APP_API_URL}/services/config`

      await axios.put(queryString, data, config)

      Swal.fire({
        title: 'Éxito',
        text: 'La configuración ha sido guardada con éxito',
        icon: 'success',
      })
    } catch (err) {
      Swal.fire({
        title: 'Error',
        text: 'Lo sentimos, ocurrió un error al guardar la configuración',
        icon: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  if (isLoading) {
    return <div>Cargando...</div>
  }

  if (error) {
    return <div>Ocurrió un error</div>
  }

  return (
    <div>
      <div className='card mb-5 mb-xl-10 mt-10' id='kt_profile_details_view'>
        <div id='kt_account_notifications' className='collapse show'>
          <form className='form' onSubmit={handleUpdateServiceConfig}>
            <div className='card-header'>
              <h3 className='card-title'>Configuración de servicios</h3>
            </div>
            <div className='card-body border-top px-9 pt-3 pb-4'>
              <h5>Procesadores de Pagos</h5>
              <div className='table-responsive'>
                <table className='table table-row-dashed border-gray-300 align-middle gy-6'>
                  <thead className='fs-6 fw-bold text-dark mb-5'>
                    <tr>
                      <td className='w-15'></td>
                      <td className='w-35'> Socio tecnológico</td>
                      <td className='w-25'>Configurable a nivel de cliente</td>
                      <td className='w-25'>Persistir esta configuración </td>
                    </tr>
                  </thead>
                  <tbody className='fs-6 fw-bold mt-5'>
                    {services.map((service) => (
                      <tr className='mb-8' key={service.id}>
                        <td className='w-15 mr-5'>
                          <input value={service.id} type='hidden' name={`service-${service.id}`} />
                          {service.alias}
                        </td>
                        <td className='w-35 mr-5'>
                          <div className='d-flex justify-content-start'>
                            <select
                              className='form-select form-select w-50'
                              name={`provider-${service.id}`}
                              value={service.providerId || ''}
                              required
                              onChange={(event) => {
                                const newServices = [...services]
                                const index = newServices.findIndex((s) => s.id === service.id)
                                newServices[index].providerId = parseInt(event.target.value, 10)
                                setServices(newServices)
                              }}
                            >
                              <option value="0">Selecciona un socio tecnológico</option>
                              {providers
                                .filter((provider) => {
                                  // Encuentra la empresa en associatedProviders
                                  const company = associatedProviders.find(
                                    (c) => c.companyName === service.alias
                                  );

                                  // Si no se encuentra la empresa, no muestres el proveedor
                                  if (!company) {
                                    return false;
                                  }

                                  // Si el proveedor está en la lista de proveedores asociados, muéstralo
                                  return company.providers.includes(provider.name);
                                })
                                .map((provider) => (
                                  <option key={provider.id} value={provider.id}>
                                    {provider.name}
                                  </option>
                                ))}

                            </select>
                          </div>
                        </td>
                        <td className='w-25 mr-5'>
                          <div className='d-flex justify-content-center w-80'>
                            <div className='form-check form-check '>
                              <input
                                className='form-check-input border border-2 border-primary rounded'
                                type='checkbox'
                                id='configByClient'
                                name={`configByClient-${service.id}`}
                                checked={service.configByClient}
                                onChange={(event) => {
                                  const newServices = [...services]
                                  const index = newServices.findIndex((s) => s.id === service.id)
                                  newServices[index].configByClient = event.target.checked
                                  setServices(newServices)
                                }}
                              />
                            </div>
                          </div>
                        </td>

                        <td className='w-25'>
                          <div className='d-flex justify-content-center w-80'>
                            <div className='form-check form-check '>
                              <input
                                className='form-check-input border border-2 border-primary rounded'
                                type='checkbox'
                                id='persistConfig'
                                name={`persistConfig-${service.id}`}
                                checked={service.configPersistent}
                                onChange={(event) => {
                                  const newServices = [...services]
                                  const index = newServices.findIndex((s) => s.id === service.id)
                                  newServices[index].configPersistent = event.target.checked
                                  setServices(newServices)
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button className='btn btn-light btn-active-light-primary me-2 rounded-pill'>Cancelar</button>
              <button type='submit' className='btn btn-primary rounded-pill'>
                {!loading && 'Guardar'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export { ServiceConfig }
