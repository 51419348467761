import axios, { AxiosResponse } from 'axios';
import { getTokenConfig } from '../../../auth';


interface VerifierResponse {
  signedUrl: string;

}


export const getMonitoringStatistics = async (client: string, interval: string, startDate?: string, endDate?: string) => {
  try {
    let queryString = `${process.env.REACT_APP_API_URL}/admin/statistics/monitoring-logs?client=${client}&interval=${interval}`;
    
    // Agregar fechas de inicio y fin si el intervalo es "Personalizado"
    if (interval === 'Personalizado' && startDate && endDate) {
      queryString += `&startDateParam=${startDate}&endDateParam=${endDate}`;
    }

    const config = await getTokenConfig();
    const response = await axios.get(queryString, config);
    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error('Failed to fetch statistics');
    }
  } catch (err) {
    console.error('Error fetching statistics:', err);
    throw err;
  }
};




  export const getDetailStatistics  = async (client: string, interval: string, startDate?: string, endDate?: string, type?: string) => {
    try {
      let queryString = `${process.env.REACT_APP_API_URL}/admin/detail-statistics?customerId=${client}&interval=${interval}`;
      if (startDate && endDate) {
        queryString += `&startDateParam=${startDate}&endDateParam=${endDate}`;
      }
      if (type) {
        queryString += `&type=${type}`;
      }
      const config = await getTokenConfig();
      const response = await axios.get(queryString, config);
  
      return response.data;
    } catch (err) {
      console.error('Error fetching monitoring statistics:', err);
      throw err;
    }
  };


  // Método para obtener transacciones de error
 
  export const getErrorTransactions = async (requestuuid: string) => {
    try {
      let queryString = `${process.env.REACT_APP_API_URL}/admin/detail-error-log?requestuuid=${requestuuid}`;
      const config = await getTokenConfig();
      const response = await axios.get(queryString, config);
  
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to fetch error transactions');
      }
    } catch (err) {
      console.error('Error fetching error transactions:', err);
      throw err;
    }
  };


  export const fetchExcelReport = async (
    createdAtDateInit: string,
    createdAtDateEnd: string,
    type: string,
    customerId:string

): Promise<string | null> => {
    try {
        let queryString = `${process.env.REACT_APP_API_URL}/download/admin/error-log-report?createdAtDateInit=${createdAtDateInit}
        &createdAtDateEnd=${createdAtDateEnd}&type=${type}&customerId=${customerId}`;
        const config = await getTokenConfig();
        const response = await axios.get(queryString, config);

        const nameReport = response.data.name;

        if (!nameReport) {
            console.error("Error al obtener el nombre del archivo.");
            return null;
        }

        let signedUrl: string | null = null;
        const interval = 5000; // Intervalo en milisegundos (en este caso, 5 segundos)
        const maxAttempts = 36; // Número máximo de intentos (12 x 5 segundos = 1 minuto)

        let attempts = 0;
        while (!signedUrl && attempts < maxAttempts) {
            signedUrl = await verifyFileReady(config, nameReport);
            if (!signedUrl) {
                await new Promise((resolve) => setTimeout(resolve, interval)); // Esperar el intervalo antes de verificar nuevamente.
                attempts++;
            }
        }

        return signedUrl;
    } catch (error) {
        console.error("Error al obtener el nombre del archivo:", error);
        return null;
    }
};




const verifyFileReady = async (config: any, nameReport: string): Promise<string | null> => {
    try {
        const verifyUrl = `${process.env.REACT_APP_API_URL}/download/admin/error-log-report?mode=check&filename=${nameReport}`;
        const response: AxiosResponse<VerifierResponse> = await axios.get(verifyUrl, config);

        console.log(response.data); // Verifica el resultado del verificador en la consola

        const signedUrl = response.data.signedUrl;
        return signedUrl || null; // Devuelve el signedUrl si está presente, o null si no lo está.
    } catch (error) {
        console.error("Error al verificar si el archivo está listo:", error);
        return null;
    }
};

export const fetchCustomers = async (

) => {
  try {
      let queryString = `${process.env.REACT_APP_API_URL}/admin/list-customers`;
      const config = await getTokenConfig();

      const response = await axios.get(queryString, config);

      const customers = response.data.data.customers;

      return { customers };
  } catch (err) {
      throw err;
  }
};