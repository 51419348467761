import { useEffect, useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import UserPool from '../../../../../src/UserPool';
import { fetchPermission } from './apis';
import CryptoJS from 'crypto-js';

interface IAuth {
  authenticated: boolean;
  loading: boolean;
}

export const useAuth = (): IAuth => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const encryptionKey = process.env.REACT_APP_ENCRIPTION_KEY;

  useEffect(() => {
    const getCognitoUser = async () => {
      setLoading(true);

      let cognitoUser: CognitoUser | null = null;
      let session: any = null;
      let token: string = '';
      let permissions: any = null;

      try {
        cognitoUser = UserPool.getCurrentUser() as CognitoUser;
        if (!cognitoUser) {
          throw new Error('No user found');
        }
        
        // Aquí asumo que getSession devuelve una Promesa
        session = await new Promise<any>((resolve, reject) => {
          cognitoUser!.getSession((err: any, result: any) => {
            err ? reject(err) : resolve(result);
          });
        });
        
        token = session.getIdToken().getJwtToken();
        
        if (!token) {
          throw new Error('No token found');
        }

        console.log('Token:', token);

        permissions = await fetchPermission(cognitoUser.getUsername(), token);
        
        console.log('Permissions:', permissions);

        if (!permissions || !encryptionKey) {
          throw new Error('Permissions or encryption key missing');
        }

        const encryptedPermissions = CryptoJS.AES.encrypt(
          JSON.stringify(permissions.user.Group.GroupPerms),
          encryptionKey
        ).toString();

        localStorage.setItem('userPermissions', encryptedPermissions);
        setAuthenticated(true);

      } catch (error) {
        console.error('Error:', error);
        setAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    getCognitoUser();
  }, []);

  return { authenticated, loading };
};
