import  { FC, useEffect, useRef, useState } from 'react';
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils';
import { fetchDataToGraph } from '../apis/requestsApi';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

interface ChartsScoreProps {
  selectedCustomer: string;
  selectedRange: string;
  startDate: string;
  endDate: string;
  timezone: string;
}

const ChartsScore: FC<ChartsScoreProps> = (props) => {
  const { selectedCustomer, selectedRange, startDate, endDate, timezone } = props;
  const circleChartRef = useRef<HTMLDivElement | null>(null);
  const [dataToGraph, setDataToGraph] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false); // Add this
  useEffect(() => {

    setIsLoading(true); // <- Agregar esto
    const fetchDataToGraphF = async () => {
      try {
        const reportParams = {
          customer: selectedCustomer,
          selectedRange: selectedRange,
          startDate: startDate,
          endDate: endDate,
          timezone: timezone,
          typeReport: 'ScoreCrediticio',
          view: 'graph1',
        };

        const data = await fetchDataToGraph(reportParams);
        setDataToGraph(data);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      } finally {
        setIsLoading(false)
      }
    };

    fetchDataToGraphF();
  }, [selectedCustomer, selectedRange, startDate, endDate, timezone]);

  useEffect(() => {
    setTimeout(() => {
      getCircleChart();
    }, 10);
  }, [dataToGraph]);

  const getCircleChart = function () {
    const chartSize: number = 150;
    const chartLine: number = 24;
    const chartRotate: number = 125;
    const el = document.getElementById('kt_card_widget_score_chart')

    if (!el) {
      return
    }
    el.innerHTML = ''

    var options = {
      size: chartSize,
      lineWidth: chartLine,
      rotate: chartRotate,
    }

    const canvas = document.createElement('canvas')
    const span = document.createElement('span')

    // @ts-ignore
    if (typeof G_vmlCanvasManager !== 'undefined') {
      // @ts-ignore
      G_vmlCanvasManager.initElement(canvas)
    }

    const ctx = canvas.getContext('2d')
    canvas.width = canvas.height = options.size

    el.appendChild(span)
    el.appendChild(canvas)
    // @ts-ignore
    ctx.translate(options.size / 2, options.size / 2) // change center
    // @ts-ignore
    ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

    const radius = (options.size - options.lineWidth) / 2

    let previousPercent = 0;
    const drawCircle = function (color: string, lineWidth: number, percent: number) {
      percent = Math.min(Math.max(0, percent || 1), 1)
      if (!ctx) {
        return
      }

      let start = previousPercent * 2 * Math.PI;
      let end = (previousPercent + percent) * 2 * Math.PI;

      ctx.beginPath();
      ctx.arc(0, 0, radius, start, end, false);
      ctx.strokeStyle = color;
      ctx.lineCap = 'round'; // butt, round or square
      ctx.lineWidth = lineWidth;
      ctx.stroke();

      previousPercent += percent;
    }

    const totalSolicitudes = dataToGraph?.dataToGraph?.total || 0;
    const totalSolicitudesWEB = dataToGraph?.dataToGraph?.totalWeb || 0;
    const totalSolicitudesAPI = dataToGraph?.dataToGraph?.totalApi || 0;

    // Calcular porcentajes
    const percentWeb = totalSolicitudesWEB / totalSolicitudes
    const percentAPI = totalSolicitudesAPI / totalSolicitudes

    console.log("Total solicitudes:", totalSolicitudes);
    console.log("Porcentaje WEB:", percentWeb);
    console.log("Porcentaje API:", percentAPI);

    // Dibujar círculos
    // Dibujar círculos
    drawCircle('#E4E6EF', options.lineWidth, 1); // draw background circle
    if (percentWeb > 0) {
      drawCircle(getCSSVariableValue('--kt-primary'), options.lineWidth, percentWeb);
    }
    if (percentAPI > 0) {
      drawCircle(getCSSVariableValue('--kt-secondary'), options.lineWidth, percentAPI);
    }
  }

  return (
    <>

      {isLoading || (dataToGraph?.dataToGraph?.totalWeb === undefined || isNaN(dataToGraph?.dataToGraph?.totalWeb)) ? (

        // Show skeleton
        <div className="card">
          <div className="card-header border-0 pt-5">

            <h2 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-1 mb-1 text-primary">SR</span>
            </h2>


          </div>
          <div className="card-body">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12 pb-4 d-flex flex-wrap align-items-center justify-content-center">
                <Skeleton
                  width={150}
                  height={50}
                  style={{ borderRadius: '4px' }}
                />
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 pb-4 d-flex flex-wrap align-items-center justify-content-center">
                <Skeleton
                  width={200}
                  height={200}
                  style={{ borderRadius: '50%' }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (


        <div className="card">
          <div className="card-header border-0 pt-5">
            <h2 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-1 mb-1 text-primary">SR</span>
            </h2>
          </div>
          <div className="card-body">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12 pb-4 d-flex flex-wrap align-items-center justify-content-center">
                <div className="d-flex flex-center me-5 pt-2">
                  <div>
                    <div className="d-flex align-items-center justify-content-left">
                      <span className="fs-3hx fw-bold text-dark me-2 lh-1 ls-n2">
                        {dataToGraph?.dataToGraph?.total || 0}
                      </span>
                    </div>
                    <span className="text-gray-400 pt-1 fw-semibold fs-8">
                      Transacciones totales de SR
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 pb-4 d-flex flex-wrap align-items-center justify-content-center">
                <div className="d-flex flex-center me-5 pt-2">
                  <div id="kt_card_widget_score_chart" ref={circleChartRef}></div>
                </div>
                <div className=" flex-column content-justify-left flex-row-right ml-7 align-items-center justify-content-center">
                  <div className="d-flex fw-semibold align-items-center justify-content-center">
                    <div className="bullet w-8px h-8px rounded-circle bg-primary me-3"></div>
                    <div className="text-gray-500 flex-grow-1 me-3">WEB</div>
                    <div className="fw-bolder text-gray-700 text-xxl-end">

                      {
                        isNaN(dataToGraph?.dataToGraph?.totalWeb)
                          ? 0
                          : new Intl.NumberFormat().format(dataToGraph?.dataToGraph?.totalWeb) || 0
                      }


                    </div>
                  </div>
                  <div className="d-flex fw-semibold align-items-center my-3 justify-content-center">
                    <div className="bullet w-8px h-8px rounded-circle bg-secondary me-3" ></div>
                    <div className="text-gray-500 flex-grow-1 me-3">API</div>
                    <div className="fw-bolder text-gray-700 text-xxl-end">
                      {
                        isNaN(dataToGraph?.dataToGraph?.totalApi)
                          ? 0
                          : new Intl.NumberFormat().format(dataToGraph?.dataToGraph?.totalApi) || 0
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>


      )
      }



    </>

  );
};

export { ChartsScore };
