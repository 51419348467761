import axios, {  AxiosResponse } from 'axios';
import { getTokenConfig } from '../../../auth';
import { SmsConfig } from './models';



export const configAlertsSMS = async () => {
  try {
    let queryString = `${process.env.REACT_APP_API_URL}/sms/config`;

    const config = await getTokenConfig();
    const response = await axios.get(queryString, config);

    console.log(response.data.data);

    // Comprobamos si smsconfig es nulo antes de desestructurarlo.
    if (response.data.data.smsconfig) {
      const { id, emails, firstAlert, secondAlert, thirdAlert, createdBy, createdAt, updatedAt } = response.data.data.smsconfig;

      // Tipamos el objeto smsConfig usando la interfaz SmsConfig
      const smsConfig: SmsConfig = {
        id,
        emails,
        firstAlert,
        secondAlert,
        thirdAlert,
        createdBy,
        createdAt,
        updatedAt
      };

      return { smsConfig };
    } else {
      // Devolvemos un objeto con propiedades nulas o valores predeterminados.
      return {
        smsConfig: {
          id: 0,
          emails: '',
          firstAlert: 0,
          secondAlert: 0,
          thirdAlert: 0,
          createdBy: null,
          createdAt: null,
          updatedAt: null
        }
      };
    }
  } catch (err) {
    console.error('Error fetching SMS configuration:', err);
    throw err;
  }
};


export const updateSMSLimitConfig = async (id: number, value: any) => {
  try {
      let queryString = `${process.env.REACT_APP_API_URL}/sms/config/${id}`;


      console.log(value)
      const config = await getTokenConfig(); 
      const response = await axios.put(queryString, value, config);

      if (response.status !== 200) {
          throw new Error('Failed to update recharge configuration');
      }

      return response.data;
  } catch (err) {
      throw err;
  }
};


export const createSMSLimitConfig = async (value: any) => {
    try {
        let queryString = `${process.env.REACT_APP_API_URL}/sms/config`;
  
  
        console.log(value)
        const config = await getTokenConfig(); 
        const response = await axios.post(queryString, value, config);
  
        if (response.status !== 200) {
            throw new Error('Failed to update recharge configuration');
        }
  
        return response.data;
    } catch (err) {
        throw err;
    }
  };


  
export const getConfigAlertsMonitoring = async () => {
  try {
    let queryString = `${process.env.REACT_APP_API_URL}/monitoring/config`;
    const config = await getTokenConfig();
    const response = await axios.get(queryString, config);

    if (response.status === 200) {
      const monitoringConfig = response.data.data.monitoringConfig; // Obtenemos el primer elemento del array

      const {  emails, firstAlert, secondAlert, thirdAlert, createdAt, updatedAt } = monitoringConfig;

      return {
        monitoringConfig: {
        
          emails,
          firstAlert,
          secondAlert,
          thirdAlert,
          createdAt,
          updatedAt
        }
      };
    } else {
      throw new Error('Failed to fetch SMS configuration');
    }
  } catch (err) {
    console.error('Error fetching SMS configuration:', err);
    throw err;
  }
};
  
  export const updateConfigAlertsMonitoring = async ( value: any) => {
    try {
      let queryString = `${process.env.REACT_APP_API_URL}/monitoring/config`;
      const config = await getTokenConfig();
      const response = await axios.put(queryString, value, config);
  
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update SMS configuration');
      }
    } catch (err) {
      console.error('Error updating SMS configuration:', err);
      throw err;
    }
  };


  export const getMonitoringStatistics = async (client: string, timeFilter: string, startDate: string, endDate: string) => {
    try {
      let queryString = `${process.env.REACT_APP_API_URL}/monitoring/statistics`;
      const config = await getTokenConfig();
  
      const params = {
        client,
        interval: timeFilter,
        startDate,
        endDate,
      };
  
      const response = await axios.get(queryString, { ...config, params });
  
      if (response.status === 200) {
        const { statistics, transactions, errorLogs } = response.data.data;
  
        return {
          statistics,
          transactions,
          errorLogs
        };
      } else {
        throw new Error('Failed to fetch monitoring statistics');
      }
    } catch (err) {
      console.error('Error fetching monitoring statistics:', err);
      throw err;
    }
  };